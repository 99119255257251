<template>
  <!-- CONTAINER DE CARD SIMPLES PARA CUSTOMIZAR - toolbar - content - actions -->
  <v-container fluid pa-5>
    <v-row justify="center" align="center" style="height:95vh">
      <v-col cols="12" xs="12" sm="7" md="5" lg="4" class="lighten-2 fill-height d-flex flex-column justify-center align-center">
        <v-card flat :color="card_color" min-width="95%" class="pt-10 mt-10">
          <div :class="['d-flex justify-center mb-3', logo_class]">
            <v-img src="@/assets/Cabbio.png" class="logo" max-height="146" max-width="240">  </v-img>
          </div>
          <!-- <v-toolbar color="primary" dark flat>
            <v-toolbar-title></v-toolbar-title>
            <v-spacer />
          </v-toolbar> -->

          <v-card-text class="pa-3 px-8">
            <!-- Slot para template de form - contents -->
            <slot name="form"></slot>
          </v-card-text>

          <!-- <v-card-actions> -->

          <!-- Slot para template de controls-->
          <v-col cols="12" class="pa-3">
            <slot name="control"></slot>
          </v-col>

          <!-- </v-card-actions> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    title: {
      type: String
    },
    card_color: {
      type: String,
      default: 'transparent'
    },
    logo_class: {
      type: String,
    }
  }
};
</script>
